.admin-interfaces-screen h1{
    position: absolute;
    left: 269px;
    top: 154px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.interfaces-container{
    display: grid;
    grid-template-rows: 380px;
    grid-template-columns: 415px 380px;
    position: absolute;
    left: 526px;
    top: 245px;
    z-index: 4;
}

.emoney-interface, .orion-interface{
    display: grid;
    grid-template-rows: 49px auto;
    padding: 29px;
    width: 320px;
    height: 313px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.interface-title-row h1{
    position: relative;
    top: -17px;
    left: 0px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.interface-title-row{
    display: grid;
    grid-template-columns: 285px 36px;
}

.form-interface{
    display: grid;
    width: 320px;
    grid-template-rows: 98px 115px 38px ;
}

.form-group-interface{
    display: grid;
    height: 100px;
    grid-template-rows: 35px 27px 5px ;
}

.form-group-interface h6{
    padding-top: 2px;
    height: 13px;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
}

.field-name-interface{
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.interface-form{
    all: unset;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}


.button-save{
    width: 320px;
    height: 52px;
    border-radius: 6px;
    background-color: #9d9d9d;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}
