.strategies-table{
    display: grid;
    grid-template-rows: repeat(4, 34px);
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.strategies-table-row{
    display: grid;
    grid-template-rows: 34px;
    grid-template-columns: 340px 190px 183px 160px 125px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);;
}

.strategies-table-data{
    padding-left: 14px;
    display: grid;
    grid-template-columns: 40px 50px;
    text-align: right;
}

.strategies-table-data p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;

}

.strategies-table-data p:nth-child(n+2){
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 105px;
}

.strategies-table-row div:nth-child(1){
    grid-template-columns: 15px 200px;
    text-align: left;
}

.strategies-table-row div:nth-child(2){
    grid-template-columns: 175px;
    text-align: left;
}

.strategies-table-row div:nth-child(4) p{
    justify-self: right;
    width: fit-content;
    margin-right: -25px;

}

.strategies-table-row div:nth-child(5) p{
    justify-self: right;
    width: fit-content;
    margin-right: -35px;
}

.strategies-table-row div:nth-child(1){
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.strategies-table div:first-child div:nth-child(n){
    background-color: #cce2dc;
}

.strategies-table div:nth-child(2) div:nth-child(n){
    background-color: #b2d3ca;
}

.strategies-table div:nth-child(3) div:nth-child(n){
    background-color: #99c5b9;
}

.strategies-table div:nth-child(4) div:nth-child(n){
    background-color: #7fb7a8;
}

.strategies-table div:nth-child(5) div:nth-child(n){
    background-color: #66a997;
}
