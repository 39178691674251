.notes-container {
    display: grid;
    grid-template-columns: 5% 92%;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-template-areas:
            "title empty"
            "label1 note1"
            "label2 note2"
            "label3 note3";
    color: rgba(0, 0, 0, 0.5);
}

.notes-container label:nth-child(1) {
    grid-area: title;
    color: rgba(0, 0, 0, 0.55);
}

.notes-container label:nth-child(n + 2) {
    text-align: end;
    font-size: 0.9em;
}

.notes-container label:nth-child(2) {
    grid-area: label1;
}

.notes-container #note-1 {
    grid-area: note1;
}

.notes-container label:nth-child(4) {
    grid-area: label2;
}

.notes-container #note-2 {
    grid-area: note2;
}

.notes-container label:nth-child(6) {
    grid-area: label3;
}

.notes-container #note-3 {
    grid-area: note3;
}

.notes-container input {
    border: none;
    padding: 2px 5px;
}

.notes-container p {
    margin: 0px;
    width: calc(100% - 6px);
    white-space: break-spaces;
    overflow: hidden;
    text-align: justify;
    padding: 0px 3px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.9em;
    animation-name: fade-new-note;
    animation-duration: 0.8s;
    transform-origin: left;
    transition-property: all;
    transition-duration: 0.5s;
    border-radius: 2px;
}

.notes-container p:hover {
    background: #ededed;
}

@keyframes fade-new-note {
    0% {opacity: 0; color: rgba(31, 79, 25, 0.8); transform: scale(0.6); }
    50% {color: rgba(31, 79, 25, 0.8);  transform: scale(1.05); }
    100% {opacity: 1; }
}