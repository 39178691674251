.admin-users-screen h1{
    position: absolute;
    left: 269px;
    top: 154px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.users-grid{
    position: absolute;
    left: 526px;
    top: 237px;
    padding: 25px;
    padding-top: 24px;
    display: grid;
    grid-template-rows: auto;
    width: 720px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.users-title-row{
    display: grid;
    grid-template-columns: 662px 36px;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 14px;
    border-bottom: 1px solid #dbdbdb;
}

.users-title-row h1{
    position: relative;
    left: 0px;
    top: 0px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.users-title-row img{
    z-index: 4;
}

.users-row{
    display: grid;
    grid-template-columns: 608px 54px 36px;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 17px;
    margin-top: 14px;
    border-bottom: 1px solid #dbdbdb;
}

.users-row h1{
    position: relative;
    left: 0px;
    top: 0px;
    margin-top: 0px;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.user{
    display: grid;
    grid-template-rows: 25px 34px;
}

.users-row img{
    margin-top: 11px;
}

.new-users-row{
    padding-top: 10px;
    display: grid;
    grid-template-columns: 207px 500px;
    height: 395px;
    border-bottom: 1px solid #dbdbdb;
}

.new-users-row h2{
    margin: 10px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.form-new-user{
    margin-top: 70px;
    display: grid;
    width: 334px;
    grid-template-rows: 108px 125px 38px ;
}