.result-container{
    width: 100%;
    display: grid;
    grid-template-rows: 46px repeat(2, 32px) 57px repeat(3, 32px);
    padding: 13px;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 13px;
    margin-bottom: 1px;
}

.consolidated-titles{
    display: grid;
    grid-template-columns: 383px repeat(8, 173px);
}

.allocations-title-item{
    width: 100%;
    height: 38px;
    border-radius: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    background-color: #7a8087;
    border: 1px solid #ffffff;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}

.consolidated-titles div:nth-child(1){
    width: 370px;
    border-radius: 6px;
    padding: 0px;
}

.consolidated-titles div:nth-child(2){
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.allocations-table{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-top: -15px;
    margin-bottom: 35px;
}

.allocations-table-row{
    display: grid;
    grid-template-rows: 32px;
    grid-template-columns: 230px 165px repeat(5, 175px);
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
}

.allocations-table-data{
    padding-left: 14px;
    display: grid;
    grid-template-columns: 15px 60px auto;
    text-align: right;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.allocations-table-row div:nth-child(-n+2){
    background-color: #cce2dc;
    padding-right: 5px;
}

.allocations-table-row div:nth-child(2){
    width: 120px;
}

.allocations-table-data p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85px;
    transition-property: all;
    transition-duration: 1s;
}

.allocations-table-row div:nth-child(n+3){
    padding-left: 20px;
}

.allocations-table-row div:nth-child(n+3) p{
    width: 105px;
}

.allocations-table-row p:nth-child(1){
    padding-left: 6px;
    text-align: left;
    font-weight: 500;
    width: 200px;
}

.allocations-tax-item, .allocations-ira-item{
    display: grid;
    grid-template-columns: repeat(2, 45%);
    height: 31.5px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
}

.total-cell{
    text-align: right;
}

.allocations-title-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 151px;
}


