.admin-menu{
    margin-right: -18px;
}

.triangle{
    position: relative;
    left: 115px;
    top: 8px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #006f51;
}

.square{
    display: grid;
    grid-template-rows: repeat(5, 26px);
    padding: 20px;
    padding-bottom: 31px;
    padding-top: 9px;
    width: 123px;
    height: 110px;
    box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    background-color: #006f51;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}

.admin-menu-options{
    cursor: default;
}
