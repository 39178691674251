.detailed-information-client{
    padding-left: 27px;
    padding-top: 29px;
    padding-right: 28px;
    padding-bottom: 16px;
    display: grid;
    grid-template-columns: 1180px auto;
    width: 1500px;
    height: 89px;
    border-radius: 8px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.client-portfolio, .client-data{
    height: 90px;
    margin-top: 0px;
}

.oval{
    margin-top: 3px;
    display: inline-block;
    margin-right: 7px;
    width: 20px;
    height: 20px;
    background-color: #2c82c9;
    border-radius: 50%;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.80;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
}

.client{
    width: fit-content;
    height: 28px;
    margin-top: 0px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.cash-flow-title{
    position: relative;
    top: -2px;
    margin-top: 0px;
    width: 593px;
    height: 28px;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);

}

.cash-flow-data{
    position: relative;
    top: -20px;
    width: 386px;
    height: 28px;
    margin-top: 0px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.current-portfolio, .ira-portfolio, .tax-portfolio{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-top: -2px;
}

.ira-portfolio{
    position: relative;
    top: -59px;
}

.tax-portfolio{
    position: relative;
    top: -118px;
}


.client-portfolio div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    left: 180px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    width: 120px;
    height: 28px;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}

.sum-1 div{
    top: -61px;
    background-color: #006f51;
}

.sum-2 div{
    top: -116px;
    background-color: #ff9e00;
}

.sum-3 div{
    top:  -172px;
    background-color: #a142b3;
}

.oval2{
    position: relative;
    display: inline-block;
    margin-top: 3px;
    margin-left: 7px;
    margin-right: 7px;
    width: 20px;
    height: 20px;
    background-color: #9466b8;
    border-radius: 50%;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.80;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
}