.manual-acc-accounts-rows{
    align-items: center;
    display: grid;
    grid-template-columns: 180px 95px auto;
    padding-top: 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 315px;
    height: 30px;
}

.manual-acc-accounts-rows p{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    padding-bottom: 3px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
    font-weight: 500;
}

.manual-acc-accounts-edit .form-manual-acc{
    margin-left: -10px;
}

.manual-acc-edit .interface-form{
    height: 36px;
    width: 75px;
}

.manual-acc-accounts-edit{
    height: fit-content;
    display: grid;
    grid-template-rows: auto;
    padding-bottom: 26px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.select-arrow{
    position: relative;
    left: 245px;
    top: 23px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid rgba(143, 143, 143, 0.6);
}

.form-group-assign-str{
    margin-top: 5px;
    margin-bottom: 16px;
    display: grid;
    grid-template-rows: 40px auto;
}

.form-group-manual-acc{
    margin-top: 5px;
    margin-bottom: 3px;
    align-items: center;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 110px 150px 10px;
}

.form-group-manual-acc .login-form{
    padding-left: 45px;
}

.manual-acc-str {
    position: relative;
    left: -60px;
    color: rgba(143, 143, 143, 0.8);
}