.account-table{
    display: grid;
    grid-template-rows: repeat(4, 32px);
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.account-table-row{
    display: grid;
    grid-template-rows: 32px;
    grid-template-columns: 120px 112px repeat(2, 144px) repeat(3, 112px) 145px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.account-table-data{
    padding-left: 14px;
    display: grid;
    grid-template-columns: 15px 60px;
    text-align: right;
}

.account-table-data p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
}


.account-table-row div:nth-child(1),
.account-table-row div:nth-child(2),
.account-table-row div:nth-child(5),
.account-table-row div:nth-child(6),
.account-table-row div:nth-child(7){
    padding-left: 6px;
    grid-template-columns: 100%;
    text-align: center;
}

.account-table-row div:nth-child(1){
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.account-table div:nth-child(1) div:first-child{
    background-color: #cce2dc;
}

.account-table div:nth-child(2) div:first-child{
    background-color: #b2d3ca;
}

.account-table div:nth-child(3) div:first-child{
    background-color: #99c5b9;
}

.account-table div:nth-child(4) div:first-child{
    background-color: #7fb7a8;
}

.account-table div:nth-child(5) div:first-child{
    background-color: #66a997;
}