.investments-table{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-top: -15px;
    margin-bottom: 35px;
}

.investments-table-row{
    display: grid;
    grid-template-rows: 32px;
    grid-template-columns: 172px 157px repeat(5, 134px);
}

.investments-table-data{
    padding-left: 14px;
    display: grid;
    grid-template-columns: 15px 60px;
    text-align: right;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.investments-table-row div:nth-child(2){
   width: 109px;
}

.investments-table-data p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 92px;
}

.investments-table-row p:nth-child(1){
    padding-left: 6px;
    text-align: left;
    font-weight: 500;
    width: 150px;
}

.tax-acc-name-div {
    position: relative;
    width: fit-content;
}

.tax-acc-name {
    position: absolute;
    left: 0;
    top: 0;
    cursor: default;
    pointer-events: none;
}

.show-full-name {
    overflow: visible !important;
    position: absolute;
    background: #e6e6eb;
    left: 0;
    top: -2px;
    width: fit-content !important;
    padding: 0px 10px;
    height: 32px;
    border-radius: 3px;
    pointer-events: none;
    display: block;
    transform: scale(0, 1);
    transition-property: transform, opacity;
    opacity: 0;
    transform-origin: left;
    transition-duration: 0.3s;
    font-weight: bold;
}

.tax-acc-name-div div {
    position: absolute;
    background: none;
    width: 170px;
    height: 100%;
}


