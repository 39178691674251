.expanded-accounts{
    margin-bottom: 30px;
    display: grid;
    grid-template-rows: 50px 65px 161px 38px 50px 161px 33px;
    grid-template-columns: 1000px;
    padding: 14px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    height: 565px;
    width: 1000px;
}

.expanded-accounts h1, .hide-accounts h1{
    margin-top: 5px;
    padding-left: 18px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.hide-accounts{
    margin-bottom: 30px;
    overflow: hidden;
    padding: 13px;
    width: 1000px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.account-header{
    display: grid;
    grid-template-rows: 56px;
    grid-template-columns: 120px 112px repeat(2, 144px) repeat(3, 112px) 145px;
}

.account-header div{
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
    background-color: #7a8087;
    border: 1px solid;
}

.account-header div:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.account-header div:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ira-totals, .taxable-totals{
    display: grid;
    grid-template-rows: 35px;
    grid-template-columns: 253px 108px 36px 109px 495px auto;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
    justify-items: right ;
}

.ira-total, .tax-total{
    padding-right: 16px;
    margin-top: 4px;
    width: 128px;
    height: 28px;
    align-content: center;
    display: grid;
    grid-template-rows: 35px;
    grid-template-columns: 20px 108px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.87);
}


