.client-list-allocations{
    position: absolute;
    top: 98px;
    display: grid;
    grid-template-rows: auto 155px repeat(6, auto);
    grid-template-columns: 1800px;
    margin-bottom: 270px;
}

.allocations-header{
    display: grid;
    grid-template-columns: 1231px 300px;
}

.form-search{
    position: relative;
    top: 75px;
    display: grid;
    width: 334px;
    border-radius: 6px;
    grid-template-columns: 280px auto;
    height: 52px;
    background-color: #ededed;
}

.search-form{
    all: unset;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}

.button-search{
    padding-top: 19px;
    padding-left: 18px;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    border: none;
}

.consolidated-h1{
    position: relative;
    left: 269px;
    top: 45px;
    white-space: nowrap;
    width: 265px;
    height: 28px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.allocations-per-client{
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: auto auto;
    grid-template-areas:
            "name notes"
            "table table";
    position: relative;
    left: 269px;
    width: 1297px;
    height: fit-content;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    margin-bottom: 20px;
    animation-name: fade-allocation-client;
    animation-duration: 0.8s;
}

.client-name-link {
    text-decoration: none;
    grid-area: name;
    margin: 29px;
    width: calc(100% - 58px);
    height: fit-content;
}

.client-name{
    width: 100%;
    height: 28px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-property: all;
    transition-duration: 0.5s;
}

.notes-section {
    grid-area: notes;
    margin: 30px 10px;
    padding: 10px 20px;
    background: #f9f9f9;
    width: calc(100% - 65px);
    border-radius: 6px;
}

.summary-container {
    grid-area: table;
}

@keyframes move-consollidated-title {
    from {top: 45px}
    to {top: -42px}
}

@keyframes expand-allocations-space {
    from {height: 1px}
    to {height: 108px}
}

@keyframes fade-allocation-client {
    from {opacity: 0; }
    to {opacity: 1; }
}