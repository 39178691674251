.expanded-withdrawals{
    display: grid;
    grid-template-rows: 55px 47px auto;
    grid-template-columns: 480px;
    padding: 13px;
    position: relative;
    width: 480px;
}

.expanded-withdrawals h1, .hide-withdrawals h1{
    margin-top: 5px;
    padding-left: 18px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.hide-withdrawals{
    overflow: hidden;
    padding: 13px;
    width: 480px;
    height: 40px;
}

.withdrawal-cols{
    display: grid;
    grid-template-columns: 73px 34px 72px 67px 130px 85px;
    justify-items: right;
}

.withdrawal-cols span{
    width: 40px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}