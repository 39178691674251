.client-strategy-modal{
    padding: 35px;
    position: absolute;
    top:26%;
    left: 37%;
    width: 310px;
    height: 460px;
    border-radius: 6px;
    box-shadow: 0 0px 0px 1800px rgba(0, 0, 0, 0.6);
    background-color: white;
    z-index: 3;
}

.client-strategy-shadows{
    padding: 35px;
    position: absolute;
    top:26%;
    left: 37%;
    width: 310px;
    height: 460px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 1);
    z-index: 2;
}

.client-strategy-modal h1{
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.form-search-client{
    display: grid;
    grid-template-rows: 80px 250px auto;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.form-search-client p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 265px;
    padding-left: 20px ;
    margin: 3px;
    color: #7a8087;
}

.form-search-client p:hover{
    transform: scale(1.02);
    font-weight: 500;
    cursor: default;
}

.form-search-client p:active{
    transform: scale(1.02);
    font-weight: 500;
    cursor: default;
    color: black;
}

.clients-list p {
    height: 2.2rem;
}


