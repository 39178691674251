

.confirm-modal{
    display: grid;
    grid-template-rows: auto;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 50px;
    position: absolute;
    top:26%;
    left: 40%;
    width: 310px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 1);
    background-color: white;
    z-index: 5;

}

.confirm-modal-shadows{
    padding: 0px;
    position: absolute;
    top:26%;
    left: 40%;
    width: 1px;
    height: 1px;
    border-radius: 6px;
    box-shadow: 0 0px 0px 1800px rgba(0, 0, 0, 0.6);
    z-index: 4;
}

.confirm-modal h1{
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
}

.button-row{
    display: grid;
    grid-template-columns: auto auto;
}

.confirm-modal .button-save{
    justify-self: right;
    margin: 10px;
    min-width: 100px;
    max-width: 160px;
    margin-top: 40px;
    margin-bottom: 15px;
}