.sort-allocations-buttons-container {
    width: 20px;
    height: 20px;
    padding: 0px;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1px;
    position: relative;
    top: 4px;
    left: 38px;
}

.sort-allocations-button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: 2px;
    background: none;
    transition-property: all;
    transition-duration: 0.5s;
}

i {
    pointer-events: none;
}

.sort-allocations-button i {
    border: solid lightgray;
    border-width: 2px 2px 0 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    position: relative;
    top: -4px;
    transition-property: all;
    transition-duration: 0.5s;
}

.sort-allocations-button.down {
    transform: rotate(180deg);
}

.sort-allocations-button.up:hover {
    background: rgba(0, 112, 79, 0.4);
}

.sort-allocations-button.down:hover {
    background: rgba(203, 16, 16, 0.4);
}

.sort-allocations-button:hover i {
    border: solid white;
    border-width: 2px 2px 0 0;
}