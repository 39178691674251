body.modal-open{
    overflow: scroll !important;
}
.advanced-settings-modal{
    padding: 20px;
    position: absolute;
    top: 100px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 50px;
    min-width: 1660px;
    min-height: 700px;
    border-radius: 6px;
    box-shadow: 0 0px 0px 1800px rgba(0, 0, 0, 0.6);
    background-color: white;
    overflow: scroll;
    z-index: 3;
}

.advanced-settings-modal-shadows{
    padding: 20px;
    position: absolute;
    top: 100px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 50px;
    min-width: 1660px;
    min-height: 700px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 1);
    z-index: 2;
}

.advanced-settings-modal h1{
    margin-left: 22px;
    font-family: Roboto, sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.advanced-settings-modal h2{
    position: relative;
    top: -39px;
    left: 217px;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.settings-close-code{
    margin-left: -45px;
    padding: 3px;
    position: relative;
    left: 100%;
    top: -75px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #006f51;
}

.settings-close-code i{
    position: relative;
    top: -2px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.settings-close-code i:first-child{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.models-container{
    margin-bottom: 20px;
    margin-left: 68px;
    display: grid;
    width: 1525px;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    overflow: scroll;
}

.models-title-row{
    margin-bottom: 10px;
    height: 56px;
    display: grid;
    grid-template-columns: 190px 205px repeat(7, 225px);
    font-weight: 500;
}

.models-title-row .models-cell{
    line-height: 1.23;
    color: rgba(255, 255, 255, 0.87);
    background-color: #7a8087;
    border: 1px solid;
}

.models-title-row div:nth-child(-n+2){
    padding: 0px;
    max-width: 190px;
}

.models-title-row div:last-child,
.models-title-row div:nth-child(2) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.models-title-row div:first-child,
.models-title-row div:nth-child(3) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.models-row{
    display: grid;
    grid-template-columns: 190px 205px auto;
    height: fit-content;
}

.percentage-buttons{
    display: grid;
    grid-template-columns: 425px 300px;
    width: 800px;
    margin: 80px;
    position: relative;
    left: 762px;

}

.models-cell p{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 191px;
}

.models-cell{
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
}

.models-name-cell p, .models-id-cell p{
    margin-top: 13px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

.models-name-cell{
    margin: 0px;
    height: 46px;
    justify-items: center;
    width: 190px;
    background-color: rgba(204, 226, 220, 0.65);
}

.models-id-cell{
    margin: 0px;
    height: 46px;
    justify-items: center;
    width: 190px;
    background-color: rgba(204, 226, 220, 1);
}


