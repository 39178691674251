.pagination{
    height: 40px;
    margin-top: 60px;
    margin-left: 280px;
    display: grid;
    grid-template-columns: 470px 450px 500px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #000000;
    text-align: center;
    white-space: nowrap;
}

.list-numbers{
    display: grid;
    grid-template-columns: repeat(9, 50px);
}

.short-list-numbers{
    margin-left: 20%;
    display: grid;
    grid-template-columns: repeat(9, 50px);
}

.pag-numbers-container{
    display: grid;
    grid-template-columns: 10px 42px 10px;
}

.pag-numbers-container div{
    width: fit-content;
    min-width: 40px;
}

.pag-numbers, .pag-next, .pag-prev {
    transition-property: all;
    transition-duration: 0.5s;
    cursor: pointer;
}

.pag-numbers:hover{
    transform: scale(1.25);
    font-weight: 500;
}

.pag-prev:hover, .pag-next:hover {
    transform: scale(1.08);
}

.pag-prev{
    margin-right: 400px;
}

.pag-next{
    margin-left: 145px;
}

.prev, .next{
    position: relative;
    top: -2px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.next{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}