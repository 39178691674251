.login-screen{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #131e2a;
}

.login{
    position: relative;
    top: 15%;
    display: grid;
    grid-template-rows: 188px 324px ;
    padding: 30px;
    padding-bottom: 0px;
    width: 334px;
    height: 515px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    overflow: hidden;
}

.login-logo{
    justify-self: center;
    width: 300px;
    height: 71px;
    margin-top: 44px;
}

.form-login{
    display: grid;
    width: 334px;
    grid-template-rows: 108px 98px 38px ;
}

.form-group-login{
    display: grid;
    grid-template-rows: 35px 28px 5px ;
}

.form-group-login h6{
    padding-top: 2px;
    height: 13px;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
}

.field-name-login{
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.login-form{
    all: unset;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}

.forgot-password{
    padding: 0px;
    position: relative;
    left: 197px;
    top: -8px;
    width: 135px;
    height: 16px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #00704f;
}


.button-login{
    width: 334px;
    height: 52px;
    border-radius: 6px;
    background-color: #00704f;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}

.button-login:active{
    background-color: #004E37;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.3);
}

.require-code{
    position: absolute;

    background-color: white;
}

button{
    border: none;
}






