.client-container-grid{
    display: grid;
    grid-template-rows: auto 48px 190px auto;
    position: absolute;
    top: 150px;
    left: 120px;
    width: 1560px;
}

.actual-screen{
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.all-sections{
    display: grid;
    grid-template-columns: 1050px auto;
}

.sections-grid{
    display: grid;
    grid-template-rows: fit-content(20px) fit-content(20px) fit-content(20px);
    grid-template-columns: 840px;
    padding-bottom: 250px;
}

@keyframes expand-desktop-space {
    from {height: 1px}
    to {height: 80px}
}



