.new-password-modal{
    padding: 20px;
    position: absolute;
    top:26%;
    left: 40%;
    width: 310px;
    height: 357px;
    border-radius: 6px;
    box-shadow: 0 0px 0px 1800px rgba(0, 0, 0, 0.6);
    background-color: white;
    z-index: 3;
}

.new-password-shadows{
    padding: 20px;
    position: absolute;
    top:26%;
    left: 40%;
    width: 310px;
    height: 357px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 1);
    z-index: 2;
}

.new-password-modal h1{
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.form-new-password{
    position: relative;
    top: -15px;
    display: grid;
    grid-template-rows: 100px 140px 50px;
}

.button-code{
    width: 310px;
    height: 52px;
    border-radius: 6px;
    background-color: #9d9d9d;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}

.close-code{
    padding: 3px;
    position: relative;
    left: 280px;
    top: -50px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #006f51;
}

.close-code i{
    position: relative;
    top: -2px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.close-code i:first-child{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}