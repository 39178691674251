.global-strategies-screen h1{
    position: absolute;
    left: 269px;
    top: 154px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.center-container{
    position: relative;
    top: 250px;
    width: 50%;
    margin: auto;
}

.outer-strategies-container{
    margin: auto;
    display: grid;
    grid-template-columns: 430px auto;
    width: fit-content;
}

.strategies-container{
    position: relative;
    display: grid;
    grid-template-rows: 57px auto;
    padding: 35px;
    width: 333px;
    height: fit-content;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.edit-strategies-container{
    position: relative;
    display: grid;
    grid-template-rows: 57px auto;
    padding: 35px;
    width: 333px;
    height: fit-content;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.strategy-title-row h1{
    position: relative;
    top: -17px;
    left: 0px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}


.strategy-title-client-id{
    position: absolute;
    left: 475px;
    top: 164px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.75);
}


.strategy-title-row, .strategy-list-row {
    display: grid;
    grid-template-columns: 303px 36px;
}

.strategy-list-row{
    margin-top: 12px;
    border-bottom: 1px solid #dbdbdb;
}

.strategy-list-row-data{
    padding-bottom: 15px;
    display: grid;
    grid-template-rows: 35px auto;
}

.strategy-list-row-data h5{
    margin: 0px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.strategy-list-specific{
    padding-left: 5px;
    display: grid;
    grid-template-columns: 160px 70px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: right;
}

.strategy-list-specific span{
    text-align: left;
}

.strategy-more-settings-row{
    margin-top: 20px;
    margin-right: 3px;
    margin-bottom: -10px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(3, 109, 81, 0.7);
}

.strategy-more-settings-row div{
    position: relative;
    left: 178px;
    width: fit-content;

}

.strategy-title-client-id:hover{
    transform : scale(1.08);
    cursor : default;
    margin-left: 12px;
}
