.forgot-login{
    position: relative;
    top: 15%;
    display: grid;
    grid-template-rows: 15px 165px 45px 85px ;
    padding: 30px;
    padding-bottom: 0px;
    width: 334px;
    height: 515px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    overflow: hidden;
}

.back-arrow{
    border: solid #036d51;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.forgot-form-login{
    display: grid;
    width: 334px;
    grid-template-rows: 123px auto ;
}