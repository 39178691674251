.form-strategy{
    display: grid;
    width: 320px;
    height: fit-content;
    grid-template-rows: auto;
}

.form-group-strategy{
    display: grid;
    height: 100px;
    grid-template-rows: 35px 27px;
}

.form-group-strategy h6{
    padding-top: 2px;
    height: 13px;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
}

.form-strategy h6{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.field-name-strategy, .form-strategy h6 span{
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.strategy-form{
    all: unset;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}

.group-strategy-timeline{
    display: grid;
    grid-template-columns: 177px auto;
}

.timeline-form{
    all: unset;
    width: 130px;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}

.button-save-str{
    margin-top: 35px;
    width: 334px;
    height: 52px;
    border-radius: 6px;
    background-color: #9d9d9d;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
}

:focus {
    outline: none !important;
}

.button-save:hover, .button-save-str:hover{
    background-color: #61666c !important;
}

.button-save:active, .button-save-str:active{
    background-color: #004E37 !important;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.3) !important;
}

