.expanded-investments{
    margin-bottom: 30px;
    height: fit-content;
    display: grid;
    grid-template-rows: 50px 65px auto 65px auto auto auto;
    grid-template-columns: 1000px;
    padding: 14px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    width: 1000px;
}

.expanded-investments h1, .hide-investments h1{
    margin-top: 5px;
    padding-left: 18px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.hide-investments{
    margin-bottom: 30px;
    overflow: hidden;
    padding: 13px;
    width: 1000px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.investments-header{
    display: grid;
    grid-template-rows: 38px;
    grid-template-columns: 326px repeat(5, 135px);
}


.investments-header div{
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.87);
    background-color: #7a8087;
    border: 1px solid;
}

.investments-header div:first-child {
    width: 268px;
    border-radius: 6px;
}

.investments-header div:nth-child(2) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.investments-header div:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}