.models-percentage-row{
    display: grid;
    grid-template-columns: repeat(5, 225px) 30px;
    height: 46px;
    overflow: hidden;
    width: 1122px;
    border-radius: 6px;
}

.models-cell-perc{
    height: 46px;
    display: grid;
    grid-template-columns: auto auto;
    width: 225px;
    text-align: center;
}

.models-cell-perc p{
    padding-right: 15px;
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-self: right;
    width: 70px;
}

.models-cell-perc span{
    margin-top: 13px;
    text-align: left;
}

.models-row div{
    height: 46px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.07);
}


.percentage-form{
    all: unset;
    margin-top: 6px;
    margin-left: 45px;
    width: 110px;
    height: 30px;
    padding: 0px;
    padding-left: 15px;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    text-align: center;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;

}

.check{
    position: relative;
    top: -2px;
    left: 2px;
    width: 19px;
    height: 9px !important;
    border: solid white;
    border-width: 2px 2px 0 0 !important;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.button-save-percentage{
    position: relative;
    top: 4px;
    left: -40px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-color: #d9d9d9;
}

.button-save-percentage :hover{
    background-color: #afafaf;
}

.button-save-percentage :active{
    background-color: #036d51;
}

@keyframes percentage-error {
    0% {background-color: rgba(210, 0, 0, 0)}
    50% {background-color: rgba(210, 0, 0, 0.3)}
    100% {background-color: rgba(210, 0, 0, 0)}
}