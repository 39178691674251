.requirements-table{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-top: -15px;
    margin-bottom: 35px;
}

.requirements-table-row{
    display: grid;
    grid-template-rows: 32px;
    grid-template-columns: 172px 157px repeat(5, 134px);
}

.requirements-table-data{
    padding-left: 14px;
    display: grid;
    grid-template-columns: 15px 60px;
    text-align: right;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.requirements-table-row div:nth-child(-n+2){
    background-color: #cce2dc;
    padding-right: 5px;
}

.requirements-table-row div:nth-child(2){
    width: 109px;
}

.requirements-table-data p{
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 92px;
}

.requirements-table-row p:nth-child(1){
    padding-left: 6px;
    text-align: left;
    font-weight: 500;
    width: 150px;
}
