.manual-accounts-screen h1{
    position: absolute;
    left: 269px;
    top: 154px;
    font-family: Roboto, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.manual-acc-client-id{
    position: absolute;
    left: 530px;
    top: 164px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.75);
}

.manual-acc-client-id:hover{
    transform : scale(1.08);
    cursor : default;
    margin-left: 12px;
}


.manual-acc-list{
    padding: 10px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: 0.62px;
    color: #000000;

}


.manual-acc-container{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 415px 380px;
    position: absolute;
    left: 526px;
    top: 245px;
    z-index: 4;
}

.ira-manual-acc, .tax-manual-acc{
    display: grid;
    grid-template-rows: 60px auto;
    padding: 29px;
    padding-bottom: 50px;
    width: 320px;
    height: auto;
    border-radius: 6px;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

.manual-acc-title-row h1{
    position: relative;
    top: -17px;
    left: 0px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.manual-acc-title-row{
    display: grid;
    grid-template-columns: 285px 36px;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.14);
}

.form-manual-acc{
    display: grid;
    width: 280px;
    grid-template-rows: 98px 98px auto 38px ;
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 40px;
}

.form-group-interface{
    display: grid;
    height: 100px;
    grid-template-rows: 35px 27px 5px ;
}

.form-group-interface h6{
    padding-top: 2px;
    height: 13px;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
}

.field-name-interface{
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.strategy-selector-container{
    height: 52px;
    background-color: #ededed;
    border-radius: 6px;
}

.strategy-selector{
    -webkit-appearance: none;
    position: relative;
    top: -9px;
    height: 52px;
    width: 270px;
    margin-left: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: transparent;
    border: none;
}


.interface-form{
    all: unset;
    height: 52px;
    padding: 0px;
    border-radius: 6px;
    padding-left: 27px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: rgba(143, 143, 143, 0.87);
    background-color: #ededed;
}

.form-manual-acc .button-save{
    margin-top: 10px;
    width: 280px;
}

.empty-manual-acc{
    width: 250px;
    margin-left: 25px;
    text-align: center;
    line-height: 1.8;
}