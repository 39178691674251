.allocations-title-long-text{
    height: 16px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: 8px;
    color: #8EA9A6;
}

.popover{
    top: -90px !important;
}

.show-title{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 25px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
    padding-top: 9px;
}

.title-image{
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 35px;
}

