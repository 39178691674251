.withdrawal-table{
    display: grid;
    grid-template-columns: 22px 75px 52px 38px 17px repeat(3, 93px);
}

.withdrawal-table div, .withdrawal-table span{
    text-align: right;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.92;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.43);
}

.withdrawal-table div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.withdrawal-table div:nth-child(8n){
    width: 87px;
    padding-right: 13px;
}

.withdrawal-table div:nth-of-type(n+1):nth-of-type(-n+9){
    background-color: #cce2dc;
}

.withdrawal-table div:nth-of-type(n+10):nth-of-type(-n+18){
    background-color: #b2d3ca;
}

.withdrawal-table div:nth-of-type(n+19):nth-of-type(-n+30){
    background-color: #99c5b9;
}

.withdrawal-table div:nth-of-type(n+31):nth-of-type(-n+45){
    background-color: #7fb7a8;
}

.withdrawal-table div:nth-of-type(n+46){
    background-color: #66a997;
}



