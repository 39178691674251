body {
  background-color: #f9f9f9;
}

header{
  position: fixed;
  z-index: 3;
  width: 100vw;
  min-width: 1000px;
  height: 98px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

button {
  cursor: pointer;
}

.allocations{
  position: absolute;
  left: 400px;
  top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 169px;
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #006f51;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.date{
  position: absolute;
  right: 15rem;
  top: 60px;
  width: auto;
  height: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  white-space: nowrap;
  color: #9d9d9d;
}

.last-update{
  display: grid;
  grid-template-columns: 210px 20px 210px;
  position: absolute;
  left: 120px;
  top: 105px;
  width: auto;
  height: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  white-space: nowrap;
  opacity: 0.8;
}

.admin-logout, .admin-menu-section{
  position: absolute;
  top: 60px;
  right: 1.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  cursor: default;
  color: #006f51;
  min-width: 50px;
}


.admin-menu-section{
  display: grid;
  grid-template-rows: 29px 200px;
  padding: 20px;
  justify-items: right;
  top: 40px;
  right: 5rem;
  width:200px;
  height: 20px;
  z-index: 3;
}

.integras-logo{
  position: absolute;
  left: 120px;
  top: 29px;
  min-width: 190px;
  height: 46px;
}

@keyframes expand-admin-menu {
  from {height: 20px}
  to {height: 190px}
}

