.enable-users span{
    position: relative;
    top: -5px;
    left: 25px;
    font-size: 13px;
    color: #afafaf;
}

.enable-users input {
    position: absolute;
    opacity: 0;
    cursor: default;
    height: 0;
    width: 0;
}

.checkmark {
    position: relative;
    top: 12px;
    left: 2px;
    height: 14px;
    width: 14px;
    border-radius: 3px;
    background-color: #afafaf;
}

.enable-users:hover input ~ .checkmark {
    background-color: #9d9d9d;
    transform: scale(1.08);
}

.enable-users input:checked ~ .checkmark {
    background-color: #006f51;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.enable-users input:checked ~ .checkmark:after {
    display: block;
}

.enable-users .checkmark:after {
    left: 4px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}